import React from 'react';
import BackButton from '../../components/BackButton';

const DataPrivacyCompliance = () => {
  return (
    <div className="container mx-auto py-16">
      <BackButton targetPath="/insights" />
      {/* Hero Section */}
      <section className="hero-section relative bg-gradient-to-r from-blue-500 to-purple-600 text-white py-20 px-4 rounded-lg mb-16 fade-in">
        <div className="text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">Data Privacy and Compliance</h1>
          <p className="text-lg max-w-2xl mx-auto animate-fade-in delay-1s">
            Explore best practices for protecting sensitive data and maintaining compliance with global privacy standards.
          </p>
        </div>
      </section>

      {/* Introduction */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">Introduction</h2>
        <p className="text-lg text-gray-700 leading-relaxed">
          With increasing data privacy regulations, organizations must prioritize data protection and compliance to avoid legal and financial consequences. Data privacy and compliance frameworks ensure that businesses handle personal information responsibly and transparently.
        </p>
      </section>

      {/* Key Compliance Standards */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">Key Compliance Standards</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          <ComplianceCard
            icon="🛡️"
            title="GDPR"
            description="The EU’s General Data Protection Regulation requires organizations to protect the personal data and privacy of EU citizens."
          />
          <ComplianceCard
            icon="📜"
            title="CCPA"
            description="The California Consumer Privacy Act grants California residents control over their personal information."
          />
          <ComplianceCard
            icon="🏥"
            title="HIPAA"
            description="The Health Insurance Portability and Accountability Act regulates data privacy and security in the healthcare sector."
          />
          <ComplianceCard
            icon="🌐"
            title="ISO 27001"
            description="An international standard that provides a framework for managing information security."
          />
        </div>
      </section>

      {/* Strategies for Maintaining Compliance */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">Strategies for Maintaining Compliance</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <StrategyCard
            icon="🔍"
            title="Regular Audits"
            description="Conduct regular audits to identify and address compliance gaps."
          />
          <StrategyCard
            icon="🔒"
            title="Data Encryption"
            description="Use encryption to protect sensitive data at rest and in transit."
          />
          <StrategyCard
            icon="🎓"
            title="Employee Training"
            description="Educate employees on data privacy best practices and regulatory requirements."
          />
          <StrategyCard
            icon="📝"
            title="Data Minimization"
            description="Collect and retain only the data necessary for business operations."
          />
          <StrategyCard
            icon="🔄"
            title="Policy Updates"
            description="Regularly update privacy policies to reflect the latest regulatory changes."
          />
          <StrategyCard
            icon="🏢"
            title="Privacy-First Culture"
            description="Foster a culture that values and prioritizes data privacy at every level of the organization."
          />
        </div>
      </section>

      {/* Conclusion & CTA */}
      <section className="cta-section text-center bg-blue-600 text-white py-16 rounded-lg">
        <h2 className="text-3xl font-semibold mb-4">Strengthen Your Data Privacy and Compliance</h2>
        <p className="text-lg mb-6">Connect with our experts to ensure your organization meets all relevant data privacy standards and regulations.</p>
        <div className="space-x-4">
          <a href="/services" className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition">Explore Our Services</a>
          <a href="/contact" className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition">Contact Us</a>
        </div>
      </section>
    </div>
  );
};

// Reusable Compliance Card Component
const ComplianceCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

// Reusable Strategy Card Component
const StrategyCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

export default DataPrivacyCompliance;
