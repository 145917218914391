// src/pages/AboutUs.js
import React from 'react';

const AboutUs = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section relative bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20">
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">About Invictus</h1>
          <p className="text-xl mb-6 animate-fade-in delay-1s">Empowering Businesses to Thrive in the Cloud</p>
          <div className="space-x-4">
            <a href="#team" className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition duration-200">
              Meet Our Team
            </a>
          </div>
        </div>
      </div>

      {/* Company Story Section with Timeline */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold mb-8 text-center">Our Journey</h2>
          <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-8">
            <TimelineCard year="2021" description="Invictus was founded with a mission to simplify cloud optimization." />
            <TimelineCard year="2022" description="Expanded our offerings to include comprehensive security and compliance solutions." />
            <TimelineCard year="2023" description="Launched Matilda partnership to empower businesses with innovative cloud solutions." />
          </div>
        </div>
      </section>

      {/* Mission, Vision, Values Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Our Mission, Vision & Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <ValuesCard icon="🌟" title="Our Mission" description="To drive business growth through optimized and secure cloud solutions." />
            <ValuesCard icon="🔍" title="Our Vision" description="Empower businesses globally to harness the full potential of cloud technology." />
            <ValuesCard icon="🤝" title="Our Values" description="Integrity, Innovation, and Customer Success." />
          </div>
        </div>
      </section>

      {/* Meet the Team Section */}
      <section className="py-16 bg-gray-50" id="team">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Meet the Team</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
            <TeamMember
              image="/images/alice.jpeg"
              name="Alice Johnson"
              title="CEO"
              bio="Alice has 20+ years in tech and is passionate about cloud innovation."
            />
            <TeamMember
              image="/images/bob.jpg"
              name="Bob Smith"
              title="CTO"
              bio="Bob leads our tech team with expertise in AI and cloud architecture."
            />
            <TeamMember
              image="/images/cathy.png"
              name="Cathy Lee"
              title="CFO"
              bio="Cathy ensures financial stability while driving business growth."
            />
            <TeamMember
              image="/images/david.jpg"
              name="David Kim"
              title="COO"
              bio="David focuses on operational excellence and customer satisfaction."
            />
          </div>
        </div>
      </section>

      {/* Customer Testimonials or Case Studies */}
      <Testimonials />

      {/* Contact CTA Section */}
      <section className="text-center bg-blue-600 text-white py-16">
        <h2 className="text-3xl font-semibold mb-4">Join Us on Our Journey</h2>
        <p className="text-lg mb-6">We’re committed to making cloud optimization accessible to all. Reach out to learn more.</p>
        <a href="/contact" className="bg-white text-blue-600 px-6 py-3 rounded font-semibold hover:bg-gray-200 transform hover:scale-105 transition duration-200">Contact Us</a>
      </section>
    </div>
  );
};

// TimelineCard Component for the Company Journey Section
const TimelineCard = ({ year, description }) => (
  <div className="flex-1 p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <h3 className="text-2xl font-bold">{year}</h3>
    <p>{description}</p>
  </div>
);

// ValuesCard Component for Mission, Vision, and Values
const ValuesCard = ({ icon, title, description }) => (
  <div className="p-4 text-center">
    <div className="text-5xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

// TeamMember Component for the Meet the Team Section
const TeamMember = ({ image, name, title, bio }) => (
  <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 text-center">
    <img src={image} alt={name} className="mx-auto rounded-full h-32 w-32 object-cover mb-4" />
    <h3 className="text-xl font-bold">{name}</h3>
    <p className="text-gray-500">{title}</p>
    <p className="text-gray-700 mt-2">{bio}</p>
  </div>
);

// Testimonials Component
const Testimonials = () => {
  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-8">What Our Clients Say</h2>
        <div className="flex justify-center space-x-8">
          <TestimonialCard
            text="Invictus helped us transform our cloud strategy and reduce costs by over 30%."
            name="John Doe"
            position="CFO"
            company="Global Manufacturing Company"
          />
          <TestimonialCard
            text="With Invictus’s expertise, we maintained compliance and optimized performance."
            name="Jane Smith"
            position="CTO"
            company="Healthcare Provider"
          />
        </div>
      </div>
    </section>
  );
};

// Individual Testimonial Card Component
const TestimonialCard = ({ text, name, position, company }) => (
  <div className="text-lg p-8 bg-white rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 max-w-sm">
    <p className="italic">“<span className="font-bold text-blue-600">{text}</span>”</p>
    <p className="font-bold mt-4">{name}, {position} at {company}</p>
  </div>
);

export default AboutUs;
