import React from 'react';
import BackButton from '../../components/BackButton';

const OptimizingCloudCosts = () => {
  return (
    <div className="container mx-auto py-16">
      <BackButton targetPath="/insights" />
      {/* Hero Section */}
      <section className="hero-section relative bg-gradient-to-r from-blue-500 to-purple-600 text-white py-20 px-4 rounded-lg mb-16 fade-in">
        <div className="text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">Optimizing Cloud Costs</h1>
          <p className="text-lg max-w-2xl mx-auto animate-fade-in delay-1s">Explore strategies to maximize your cloud savings and improve cost-efficiency without compromising on performance.</p>
        </div>
      </section>

      {/* Introduction */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">Introduction</h2>
        <p className="text-lg text-gray-700 leading-relaxed">
          Cloud cost optimization is essential for organizations aiming to reduce expenses and maximize the value of their cloud investments. By implementing effective strategies, you can gain better visibility, control, and efficiency over your cloud costs.
        </p>
      </section>

      {/* Key Strategies */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">Key Strategies for Cost Optimization</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <StrategyCard
            icon="💸"
            title="Rightsizing Resources"
            description="Analyze your cloud resources and adjust them to align with actual usage demands, minimizing idle or underutilized resources."
          />
          <StrategyCard
            icon="🔄"
            title="Automate Scaling"
            description="Implement automated scaling to dynamically adjust resources based on workload demands, preventing over-provisioning."
          />
          <StrategyCard
            icon="📈"
            title="Monitor Usage Patterns"
            description="Regularly track usage patterns and identify opportunities to switch to cost-effective plans or pricing models."
          />
          <StrategyCard
            icon="💡"
            title="Leverage Spot Instances"
            description="Use spot instances or reserved instances for workloads with flexible timing to lower compute costs."
          />
          <StrategyCard
            icon="🔍"
            title="Implement Cost Monitoring Tools"
            description="Adopt tools that provide insights into spending, allowing you to set budgets and track cost anomalies."
          />
          <StrategyCard
            icon="⚙️"
            title="Automate Off-Peak Scaling"
            description="Automatically scale down or shut off resources during off-peak hours to reduce costs without impacting performance."
          />
        </div>
      </section>

      {/* Detailed Content Section */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">In-Depth Guide to Cost Optimization</h2>
        <p className="text-lg text-gray-700 leading-relaxed mb-6">
          To fully optimize cloud costs, it's essential to have a detailed approach that incorporates both proactive and reactive strategies. Here are some steps to help you build an efficient and cost-effective cloud environment.
        </p>
        <ul className="list-disc list-inside text-lg text-gray-700">
          <li className="mb-3"><strong>Conduct Regular Audits:</strong> Frequently analyze your cloud usage and expenses to identify optimization opportunities.</li>
          <li className="mb-3"><strong>Enable Reserved and Spot Instances:</strong> Use reserved instances for predictable workloads and spot instances for flexible tasks.</li>
          <li className="mb-3"><strong>Optimize Storage Tiers:</strong> Choose the appropriate storage tiers based on access patterns and retention requirements.</li>
          <li className="mb-3"><strong>Optimize Data Transfer Costs:</strong> Minimize inter-region and intra-region data transfer to reduce additional charges.</li>
          <li className="mb-3"><strong>Set Budget Alerts:</strong> Configure budget alerts and notifications to monitor spending and prevent cost overruns.</li>
        </ul>
      </section>

      {/* Call to Action */}
      <section className="cta-section text-center bg-blue-600 text-white py-16 rounded-lg">
        <h2 className="text-3xl font-semibold mb-4">Ready to Optimize Your Cloud Costs?</h2>
        <p className="text-lg mb-6">Connect with our cloud experts to discover how Invictus can help you achieve cost efficiency in your cloud environment.</p>
        <div className="space-x-4">
          <a href="/services" className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition">Explore Our Services</a>
          <a href="/contact" className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition">Contact Us</a>
        </div>
      </section>
    </div>
  );
};

// Reusable Strategy Card Component
const StrategyCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

export default OptimizingCloudCosts;
