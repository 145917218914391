import React from 'react';

const Contact = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section relative bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20 fade-in">
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">Contact Us</h1>
          <p className="text-xl mb-6 animate-fade-in delay-1s">We're here to help you with your cloud journey. Get in touch with us today.</p>
        </div>
      </div>

      {/* Contact Form and Information Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto lg:max-w-screen-lg grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Contact Form */}
          <div className="bg-white p-8 rounded-lg shadow-md">
            <h2 className="text-3xl font-bold mb-6">Send Us a Message</h2>
            <form>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">Name</label>
                <input
                  type="text"
                  placeholder="Your name"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">Email</label>
                <input
                  type="email"
                  placeholder="Your email"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">Message</label>
                <textarea
                  placeholder="Your message"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-transform transform hover:scale-105"
              >
                Send Message
              </button>
            </form>
          </div>

          {/* Contact Information */}
          <div className="text-center md:text-left">
            <h2 className="text-3xl font-bold mb-6">Contact Information</h2>
            <p className="text-lg mb-4">
              If you prefer, reach out to us directly using the contact details below.
            </p>
            <div className="text-gray-700 space-y-4">
              <p className="flex items-center justify-center md:justify-start">
                <span className="text-2xl mr-2">📞</span> +1 (234) 567-890
              </p>
              <p className="flex items-center justify-center md:justify-start">
                <span className="text-2xl mr-2">✉️</span> contact@invictus.ai
              </p>
              <p className="flex items-center justify-center md:justify-start">
                <span className="text-2xl mr-2">📍</span> 1234 Cloud Lane, San Francisco, CA
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Optional Map Section */}
      <section className="bg-gray-200 py-16">
        <div className="container mx-auto text-center lg:max-w-screen-lg">
          <h2 className="text-3xl font-bold mb-6">Our Location</h2>
          <iframe
            title="Our Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345093745!2d144.9537353153156!3d-37.81627927975148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d43f1f6a4fb%3A0xebf92c4a3d65e5f6!2s1234+Cloud+Lane%2C+San+Francisco%2C+CA!5e0!3m2!1sen!2sus!4v1639601234567!5m2!1sen!2sus"
            width="100%"
            height="300"
            allowFullScreen=""
            loading="lazy"
            className="rounded-lg shadow-lg"
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default Contact;
