// src/pages/Services.js
import React from 'react';

const Services = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section relative bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20 fade-in">
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">Our Cloud Services</h1>
          <p className="text-xl mb-6 animate-fade-in delay-1s">Discover how Invictus can help you optimize, secure, and scale your cloud infrastructure.</p>
        </div>
      </div>

      {/* Services Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-12">What We Offer</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            <ServiceCard
              icon="💰"
              title="Cost Optimization"
              description="Reduce your cloud expenses by identifying unused resources and right-sizing instances."
            />
            <ServiceCard
              icon="🛡️"
              title="Security & Compliance"
              description="Protect your data with real-time monitoring, threat detection, and compliance automation."
            />
            <ServiceCard
              icon="⚙️"
              title="Infrastructure Management"
              description="Keep your infrastructure running smoothly with proactive maintenance and automation."
            />
            <ServiceCard
              icon="📊"
              title="FinOps Consulting"
              description="Gain insights into your cloud spending and make data-driven decisions with FinOps."
            />
            <ServiceCard
              icon="🔄"
              title="Migration Services"
              description="Effortlessly migrate your applications and data to the cloud with minimal disruption."
            />
            <ServiceCard
              icon="🌐"
              title="Multi-Cloud Support"
              description="Leverage multiple cloud platforms to optimize performance, security, and redundancy."
            />
          </div>
        </div>
      </section>

      {/* Key Benefits Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Why Choose Invictus?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <BenefitCard
              icon="⚡"
              title="Enhanced Performance"
              description="We optimize your cloud setup for maximum speed and reliability."
            />
            <BenefitCard
              icon="🔒"
              title="Unmatched Security"
              description="Our solutions prioritize security to protect your data and applications."
            />
            <BenefitCard
              icon="📈"
              title="Cost Efficiency"
              description="We help you save on cloud costs with our cost-effective solutions."
            />
          </div>
        </div>
      </section>

      {/* Contact CTA Section */}
      <section className="text-center bg-blue-600 text-white py-16">
        <h2 className="text-3xl font-semibold mb-4">Ready to Transform Your Cloud Experience?</h2>
        <p className="text-lg mb-6">Contact our team to discuss your specific cloud needs and discover the right solution for your business.</p>
        <a href="/contact" className="bg-white text-blue-600 px-6 py-3 rounded font-semibold hover:bg-gray-200 transform hover:scale-105 transition duration-200">Get in Touch</a>
      </section>
    </div>
  );
};

// ServiceCard Component for each service
const ServiceCard = ({ icon, title, description }) => (
  <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-5xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
    <a href="/contact" className="text-blue-500 hover:underline mt-4 inline-block">Learn More</a>
  </div>
);

// BenefitCard Component for key benefits
const BenefitCard = ({ icon, title, description }) => (
  <div className="p-4 text-center bg-gray-50 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-5xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

export default Services;
