import React from 'react';
import BackButton from '../../components/BackButton';

const AICybersecurity = () => {
  return (
    <div className="container mx-auto py-16">
      <BackButton targetPath="/insights" />
      {/* Hero Section */}
      <section className="hero-section relative bg-gradient-to-r from-blue-500 to-purple-600 text-white py-20 px-4 rounded-lg mb-16 fade-in">
        <div className="text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">AI in Cybersecurity</h1>
          <p className="text-lg max-w-2xl mx-auto animate-fade-in delay-1s">Explore how artificial intelligence is transforming cybersecurity, enhancing threat detection, and securing digital environments.</p>
        </div>
      </section>

      {/* Introduction */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">Introduction</h2>
        <p className="text-lg text-gray-700 leading-relaxed">
          With the growing complexity of cyber threats, artificial intelligence (AI) has become a critical tool in strengthening cybersecurity frameworks. AI enables faster and more accurate detection of potential threats, allowing organizations to proactively protect their data and assets.
        </p>
      </section>

      {/* Key Benefits of AI in Cybersecurity */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">Key Benefits of AI in Cybersecurity</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <BenefitCard
            icon="🔍"
            title="Enhanced Threat Detection"
            description="AI can quickly identify unusual patterns, making it easier to detect and prevent potential cyber attacks."
          />
          <BenefitCard
            icon="⚙️"
            title="Automated Response"
            description="Automate threat responses, minimizing human intervention and reducing response time for critical incidents."
          />
          <BenefitCard
            icon="📊"
            title="Predictive Analytics"
            description="Use AI to analyze past threats and predict future vulnerabilities, allowing for proactive defense."
          />
          <BenefitCard
            icon="🔒"
            title="Improved Security Posture"
            description="AI-driven insights help organizations strengthen their overall security strategies and reduce vulnerabilities."
          />
          <BenefitCard
            icon="🛡️"
            title="Real-Time Monitoring"
            description="AI provides continuous monitoring to identify threats as they occur, ensuring round-the-clock security."
          />
          <BenefitCard
            icon="💡"
            title="Reduced False Positives"
            description="AI algorithms help filter out false positives, allowing security teams to focus on genuine threats."
          />
        </div>
      </section>

      {/* Detailed Content Section */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">How AI Enhances Cybersecurity</h2>
        <p className="text-lg text-gray-700 leading-relaxed mb-6">
          AI enhances cybersecurity by providing advanced tools and techniques to detect, prevent, and respond to threats effectively. Here’s how AI-driven cybersecurity works:
        </p>
        <ul className="list-disc list-inside text-lg text-gray-700">
          <li className="mb-3"><strong>Anomaly Detection:</strong> AI identifies unusual patterns in data, helping detect threats that might go unnoticed.</li>
          <li className="mb-3"><strong>Machine Learning Models:</strong> Machine learning algorithms analyze vast amounts of data to recognize new threats based on historical data.</li>
          <li className="mb-3"><strong>Behavioral Analysis:</strong> AI examines user behavior to spot irregularities that may indicate compromised accounts.</li>
          <li className="mb-3"><strong>Natural Language Processing (NLP):</strong> NLP helps process and understand security threat intelligence reports and alerts.</li>
          <li className="mb-3"><strong>Automated Threat Response:</strong> AI can trigger predefined actions in response to detected threats, reducing response time and mitigating impact.</li>
        </ul>
      </section>

      {/* Call to Action */}
      <section className="cta-section text-center bg-blue-600 text-white py-16 rounded-lg">
        <h2 className="text-3xl font-semibold mb-4">Enhance Your Cybersecurity with AI</h2>
        <p className="text-lg mb-6">Connect with our experts to discover how AI can strengthen your cybersecurity framework and protect your digital assets.</p>
        <div className="space-x-4">
          <a href="/services" className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition">Explore Our Services</a>
          <a href="/contact" className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition">Contact Us</a>
        </div>
      </section>
    </div>
  );
};

// Reusable Benefit Card Component
const BenefitCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

export default AICybersecurity;
