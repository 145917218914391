// src/pages/Insights.js
import React from 'react';
import { Link } from 'react-router-dom';

const Insights = () => {
  return (
    <div className="container mx-auto py-16">
      <h1 className="text-5xl font-bold text-center mb-12">Insights</h1>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Cloud Cost Optimization Card */}
        <InsightCard
          title="Cloud Cost Optimization"
          description="Learn how to reduce cloud expenses through efficient management and optimization strategies."
          link="/insights/optimizing-cloud-costs"
          color="from-blue-500 to-purple-600"
          icon="💰"
          hoverColor="text-blue-600"
        />

        {/* AI in Cybersecurity Card */}
        <InsightCard
          title="AI in Cybersecurity"
          description="Explore the role of AI in enhancing cybersecurity measures and threat detection."
          link="/insights/ai-in-cybersecurity"
          color="from-green-500 to-teal-600"
          icon="🛡️"
          hoverColor="text-green-600"
        />

        {/* Multi-Cloud Management Card */}
        <InsightCard
          title="Multi-Cloud Management"
          description="A guide to managing multi-cloud environments efficiently."
          link="/insights/multi-cloud-management"
          color="from-yellow-500 to-orange-600"
          icon="☁️"
          hoverColor="text-yellow-600"
        />

        {/* Digital Transformation Strategy Card */}
        <InsightCard
          title="Digital Transformation Strategy"
          description="Learn how to drive digital innovation in your organization."
          link="/insights/digital-transformation-strategy"
          color="from-purple-500 to-pink-600"
          icon="🚀"
          hoverColor="text-purple-600"
        />

        {/* Data Privacy and Compliance Card */}
        <InsightCard
          title="Data Privacy and Compliance"
          description="Understand the latest in data protection and compliance best practices."
          link="/insights/data-privacy-compliance"
          color="from-red-500 to-pink-600"
          icon="🔒"
          hoverColor="text-red-600"
        />

        {/* Cloud Security Best Practices Card */}
        <InsightCard
          title="Cloud Security Best Practices"
          description="Explore best practices for securing your cloud infrastructure."
          link="/insights/cloud-security-best-practices"
          color="from-indigo-500 to-blue-700"
          icon="🔐"
          hoverColor="text-indigo-600"
        />
      </div>
    </div>
  );
};

// Reusable Insight Card Component with Link wrapping the card for full clickability
const InsightCard = ({ title, description, link, color, icon, hoverColor }) => (
  <Link to={link} className="block">
    <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow transform hover:scale-105 relative group overflow-hidden min-h-[280px]">
      <div className={`absolute inset-0 bg-gradient-to-r ${color} opacity-20 group-hover:opacity-30 transition-opacity`}></div>
      <h2 className={`text-3xl font-semibold mb-4 text-gray-800 group-hover:${hoverColor} transition-colors`}>
        {title}
      </h2>
      <p className="text-gray-700 mb-4">{description}</p>
      <div className="inline-block bg-blue-600 text-white px-4 py-2 rounded-full text-sm font-semibold shadow-md hover:bg-blue-700 transition">
        Read More
      </div>
      <div className={`absolute top-4 right-4 text-4xl text-blue-100 group-hover:${hoverColor} transition-colors`}>
        {icon}
      </div>
    </div>
  </Link>
);

export default Insights;
