// src/components/Footer.js
import React from 'react';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-6 relative">
      <div className="container mx-auto text-center">
        <p className="text-sm">&copy; 2024 Invictus Optimize. All rights reserved.</p>
        <p className="text-xs mt-1">Empowering your cloud journey with insights and efficiency.</p>

        {/* Social Media Icons */}
        <div className="flex justify-center space-x-4 mt-4">
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
            <FaLinkedin className="text-white hover:text-blue-500" size={24} />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter className="text-white hover:text-blue-400" size={24} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
