// src/pages/Home.js
import React from 'react';
import Slider from "react-slick"; // Ensure react-slick and slick-carousel are installed
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section relative bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20">
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">Optimize Your Cloud, Maximize Your Savings</h1>
          <p className="text-xl mb-6 animate-fade-in delay-1s">Invictus helps you reduce costs, enhance security, and achieve compliance across multi-cloud environments with AI-powered insights.</p>
          <div className="space-x-4">
            <a href="/services" className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transform hover:scale-105 transition duration-200">
              Explore Our Solutions
            </a>
            <a href="/about" className="text-white underline hover:text-gray-200 transform hover:scale-105 transition duration-200">Learn More</a>
          </div>
        </div>
      </div>

      {/* Core Features Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-12">Our Core Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              icon="💰"
              title="Cost Control Suite"
              description="Reduce costs through rightsizing, usage tracking, and automated management."
            />
            <FeatureCard
              icon="🛡️"
              title="Security Shield"
              description="Real-time monitoring, compliance automation, and threat detection."
            />
            <FeatureCard
              icon="⚙️"
              title="Operational Excellence"
              description="Ensure high performance and availability through proactive management."
            />
          </div>
        </div>
      </section>

      {/* Testimonials Carousel */}
      <Testimonials />


      {/* Client Logos Section */}
      <section className="bg-white py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-semibold mb-6">Trusted by Leading Brands</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <img src="/logos/visa.png" alt="Visa" className="mx-auto h-16 object-contain" />
            <img src="/logos/pg.png" alt="P&G" className="mx-auto h-16 object-contain" />
            <img src="/logos/coke.png" alt="Coca Cola" className="mx-auto h-16 object-contain" />
            <img src="/logos/jnj.png" alt="Johnson & Johnson" className="mx-auto h-16 object-contain" />
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section className="text-center bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16">
        <h2 className="text-3xl font-semibold mb-4">Ready to Transform Your Cloud Environment?</h2>
        <p className="text-lg mb-6">Let’s work together to achieve your cloud goals. Get in touch with our experts today.</p>
        <div className="space-x-4">
          <a href="/services" className="bg-white text-blue-600 px-6 py-3 rounded font-semibold hover:bg-gray-200 transform hover:scale-105 transition duration-200">Explore Our Services</a>
          <a href="/contact" className="bg-white text-blue-600 px-6 py-3 rounded font-semibold hover:bg-gray-200 transform hover:scale-105 transition duration-200">Contact Us</a>
        </div>
      </section>
    </div>
  );
};

// Reusable FeatureCard Component
const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-5xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
    <a href="/services" className="text-blue-500 hover:underline mt-4 inline-block">Learn More</a>
  </div>
);

// Testimonials Carousel Component
const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true, // Enables navigation arrows
  };

  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-8">What Our Clients Say</h2>
        <Slider {...settings}>
          <TestimonialCard
            text="Invictus helped us save over 25% on cloud costs, which allowed us to reallocate resources towards new growth initiatives."
            name="John Doe"
            position="CFO"
            company="Global Manufacturing Company"
          />
          <TestimonialCard
            text="With Invictus’s Security Shield, we were able to maintain continuous HIPAA compliance with ease."
            name="Jane Smith"
            position="CTO"
            company="Healthcare Provider"
          />
        </Slider>
      </div>
    </section>
  );
};

// Individual Testimonial Card Component
const TestimonialCard = ({ text, name, position, company }) => (
  <div className="text-lg p-8">
    <p className="italic">“<span className="font-bold text-blue-600">{text}</span>”</p>
    <p className="font-bold mt-4">{name}, {position} at {company}</p>
  </div>
);

export default Home;
