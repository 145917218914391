import React from 'react';
import BackButton from '../../components/BackButton';

const CloudSecurityBestPractices = () => {
  return (
    <div className="container mx-auto py-16">
      <BackButton targetPath="/insights" />
      {/* Hero Section */}
      <section className="hero-section relative bg-gradient-to-r from-indigo-500 to-blue-700 text-white py-20 px-4 rounded-lg mb-16 fade-in">
        <div className="text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">Cloud Security Best Practices</h1>
          <p className="text-lg max-w-2xl mx-auto animate-fade-in delay-1s">
            Discover essential practices to secure your cloud environment, protect data, and ensure compliance.
          </p>
        </div>
      </section>

      {/* Introduction */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">Introduction</h2>
        <p className="text-lg text-gray-700 leading-relaxed">
          As more organizations move to the cloud, ensuring security has become crucial. Cloud security best practices help protect sensitive data, secure user access, and prevent cyber threats.
        </p>
      </section>

      {/* Core Security Principles */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">Core Security Principles</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          <SecurityPrincipleCard
            icon="🔒"
            title="Data Protection"
            description="Encrypt data both in transit and at rest to ensure its security."
          />
          <SecurityPrincipleCard
            icon="👤"
            title="Identity and Access Management"
            description="Use IAM policies to manage access and permissions effectively."
          />
          <SecurityPrincipleCard
            icon="🔍"
            title="Threat Detection"
            description="Implement continuous monitoring to detect and respond to threats in real time."
          />
          <SecurityPrincipleCard
            icon="✅"
            title="Compliance"
            description="Ensure compliance with industry standards like GDPR and HIPAA to mitigate legal risks."
          />
        </div>
      </section>

      {/* Best Practices for Cloud Security */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">Best Practices for Cloud Security</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <BestPracticeCard
            icon="🔐"
            title="Enable Multi-Factor Authentication"
            description="Require multi-factor authentication to add an extra layer of security for user accounts."
          />
          <BestPracticeCard
            icon="📈"
            title="Continuous Monitoring"
            description="Monitor your cloud environment in real-time to detect anomalies and potential threats."
          />
          <BestPracticeCard
            icon="🛠️"
            title="Regular Vulnerability Assessments"
            description="Conduct regular vulnerability scans to identify and fix security weaknesses."
          />
          <BestPracticeCard
            icon="🗄️"
            title="Data Backup and Recovery"
            description="Ensure regular backups and have a recovery plan to safeguard against data loss."
          />
          <BestPracticeCard
            icon="💻"
            title="Endpoint Protection"
            description="Protect endpoints like computers and mobile devices from malware and unauthorized access."
          />
          <BestPracticeCard
            icon="🔄"
            title="Patch Management"
            description="Keep systems up-to-date with the latest security patches to prevent vulnerabilities."
          />
        </div>
      </section>

      {/* Conclusion & CTA */}
      <section className="cta-section text-center bg-blue-600 text-white py-16 rounded-lg">
        <h2 className="text-3xl font-semibold mb-4">Secure Your Cloud with Best Practices</h2>
        <p className="text-lg mb-6">Connect with our security experts to ensure your cloud environment is protected against threats and compliant with standards.</p>
        <div className="space-x-4">
          <a href="/services" className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition">Explore Our Services</a>
          <a href="/contact" className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition">Contact Us</a>
        </div>
      </section>
    </div>
  );
};

// Reusable Security Principle Card Component
const SecurityPrincipleCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

// Reusable Best Practice Card Component
const BestPracticeCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

export default CloudSecurityBestPractices;
