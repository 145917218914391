import React from 'react';
import BackButton from '../../components/BackButton';

const MultiCloudManagement = () => {
  return (
    <div className="container mx-auto py-16">
      <BackButton targetPath="/insights" />
      {/* Hero Section */}
      <section className="hero-section relative bg-gradient-to-r from-blue-500 to-purple-600 text-white py-20 px-4 rounded-lg mb-16 fade-in">
        <div className="text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">Multi-Cloud Management</h1>
          <p className="text-lg max-w-2xl mx-auto animate-fade-in delay-1s">
            Discover effective strategies to manage and optimize multi-cloud environments, ensuring efficiency, security, and cost control.
          </p>
        </div>
      </section>

      {/* Introduction */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">Introduction to Multi-Cloud Management</h2>
        <p className="text-lg text-gray-700 leading-relaxed">
          Multi-cloud management involves coordinating resources and workloads across multiple cloud providers. While it offers flexibility and redundancy, it also requires careful strategy to address challenges such as data integration, security, and cost management.
        </p>
      </section>

      {/* Key Challenges */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">Key Challenges of Multi-Cloud Management</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <ChallengeCard
            icon="🔗"
            title="Data Integration"
            description="Ensuring seamless data flow and consistency across different cloud platforms."
          />
          <ChallengeCard
            icon="🔒"
            title="Security"
            description="Maintaining consistent security and compliance standards across multiple providers."
          />
          <ChallengeCard
            icon="💸"
            title="Cost Management"
            description="Monitoring and optimizing costs to avoid overspending in a multi-cloud setup."
          />
        </div>
      </section>

      {/* Best Practices */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">Best Practices for Multi-Cloud Management</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <PracticeCard
            icon="📊"
            title="Unified Monitoring"
            description="Implement a centralized monitoring solution to oversee all cloud services from a single interface."
          />
          <PracticeCard
            icon="🔐"
            title="Automate Compliance"
            description="Use automation to enforce security policies consistently across all environments."
          />
          <PracticeCard
            icon="💰"
            title="Optimize Costs"
            description="Regularly review and optimize resource usage to keep cloud costs under control."
          />
        </div>
      </section>

      {/* Detailed Content Section */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">How to Successfully Manage Multi-Cloud Environments</h2>
        <p className="text-lg text-gray-700 leading-relaxed mb-6">
          Effective multi-cloud management combines the right tools and practices to streamline operations and enhance flexibility. Here’s a look at some strategies:
        </p>
        <ul className="list-disc list-inside text-lg text-gray-700">
          <li className="mb-3"><strong>Centralized Management:</strong> Use a single platform to manage resources across multiple clouds.</li>
          <li className="mb-3"><strong>Automated Workflows:</strong> Automate routine tasks to ensure consistency and reduce operational overhead.</li>
          <li className="mb-3"><strong>Cross-Cloud Security:</strong> Implement security protocols that apply across all cloud environments.</li>
          <li className="mb-3"><strong>Regular Cost Audits:</strong> Track and analyze spending to identify opportunities for cost savings.</li>
        </ul>
      </section>

      {/* Call to Action */}
      <section className="cta-section text-center bg-blue-600 text-white py-16 rounded-lg">
        <h2 className="text-3xl font-semibold mb-4">Optimize Your Multi-Cloud Strategy with Invictus</h2>
        <p className="text-lg mb-6">Connect with our experts to create a customized multi-cloud management solution for your business needs.</p>
        <div className="space-x-4">
          <a href="/services" className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition">Explore Our Services</a>
          <a href="/contact" className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition">Contact Us</a>
        </div>
      </section>
    </div>
  );
};

// Reusable Challenge Card Component
const ChallengeCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

// Reusable Practice Card Component
const PracticeCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

export default MultiCloudManagement;
