// src/components/Navbar.js
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [insightsDropdownOpen, setInsightsDropdownOpen] = useState(false);
  const [resourcesDropdownOpen, setResourcesDropdownOpen] = useState(false);
  const insightsDropdownRef = useRef(null);
  const resourcesDropdownRef = useRef(null);
  let insightsTimeout;
  let resourcesTimeout;

  const toggleInsightsDropdown = () => {
    clearTimeout(insightsTimeout);
    setInsightsDropdownOpen(!insightsDropdownOpen);
  };

  const toggleResourcesDropdown = () => {
    clearTimeout(resourcesTimeout);
    setResourcesDropdownOpen(!resourcesDropdownOpen);
  };

  const handleMouseEnterInsights = () => {
    clearTimeout(insightsTimeout);
    setInsightsDropdownOpen(true);
  };

  const handleMouseLeaveInsights = () => {
    insightsTimeout = setTimeout(() => setInsightsDropdownOpen(false), 300); // 300ms delay
  };

  const handleMouseEnterResources = () => {
    clearTimeout(resourcesTimeout);
    setResourcesDropdownOpen(true);
  };

  const handleMouseLeaveResources = () => {
    resourcesTimeout = setTimeout(() => setResourcesDropdownOpen(false), 300); // 300ms delay
  };

  const closeDropdowns = () => {
    setInsightsDropdownOpen(false);
    setResourcesDropdownOpen(false);
  };

  return (
    <nav className="bg-white shadow-md fixed w-full top-0 z-50">
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold text-blue-600">Invictus Optimize</Link>
        <div className="flex items-center space-x-6">
          <Link to="/" className="hover:text-blue-600">Home</Link>
          <Link to="/about" className="hover:text-blue-600">About Us</Link>
          <Link to="/services" className="hover:text-blue-600">Services</Link>

          {/* Insights Dropdown */}
          <div
            className="relative"
            onMouseEnter={handleMouseEnterInsights}
            onMouseLeave={handleMouseLeaveInsights}
            ref={insightsDropdownRef}
          >
            <button onClick={toggleInsightsDropdown} className="hover:text-blue-600 focus:outline-none">
              Insights
            </button>
            {insightsDropdownOpen && (
              <div className="absolute top-full left-0 mt-2 w-48 bg-white shadow-lg rounded-md z-10">
                <Link
                  to="/insights"
                  className="block px-4 py-2 hover:bg-blue-100"
                  onClick={closeDropdowns}
                >
                  All
                </Link>
                <Link
                  to="/insights/optimizing-cloud-costs"
                  className="block px-4 py-2 hover:bg-blue-100"
                  onClick={closeDropdowns}
                >
                  Cloud Cost Optimization
                </Link>
                <Link
                  to="/insights/ai-in-cybersecurity"
                  className="block px-4 py-2 hover:bg-blue-100"
                  onClick={closeDropdowns}
                >
                  AI in Cybersecurity
                </Link>
                <Link
                  to="/insights/multi-cloud-management"
                  className="block px-4 py-2 hover:bg-blue-100"
                  onClick={closeDropdowns}
                >
                  Multi-Cloud Management
                </Link>
                <Link
                  to="/insights/digital-transformation-strategy"
                  className="block px-4 py-2 hover:bg-blue-100"
                  onClick={closeDropdowns}
                >
                  Digital Transformation Strategy
                </Link>
                <Link
                  to="/insights/data-privacy-compliance"
                  className="block px-4 py-2 hover:bg-blue-100"
                  onClick={closeDropdowns}
                >
                  Data Privacy and Compliance
                </Link>
                <Link
                  to="/insights/cloud-security-best-practices"
                  className="block px-4 py-2 hover:bg-blue-100"
                  onClick={closeDropdowns}
                >
                  Cloud Security Best Practices
                </Link>
              </div>
            )}
          </div>

          {/* Resources Dropdown */}
          <div
            className="relative"
            onMouseEnter={handleMouseEnterResources}
            onMouseLeave={handleMouseLeaveResources}
            ref={resourcesDropdownRef}
          >
            <button onClick={toggleResourcesDropdown} className="hover:text-blue-600 focus:outline-none">
              Resources
            </button>
            {resourcesDropdownOpen && (
              <div className="absolute top-full left-0 mt-2 w-48 bg-white shadow-lg rounded-md z-10">
                <Link
                  to="/blogs"
                  className="block px-4 py-2 hover:bg-blue-100"
                  onClick={closeDropdowns}
                >
                  Blogs
                </Link>
                <Link
                  to="/whitepapers"
                  className="block px-4 py-2 hover:bg-blue-100"
                  onClick={closeDropdowns}
                >
                  Whitepapers
                </Link>
                <Link
                  to="/testimonials"
                  className="block px-4 py-2 hover:bg-blue-100"
                  onClick={closeDropdowns}
                >
                  Testimonials
                </Link>
              </div>
            )}
          </div>

          <Link to="/contact" className="hover:text-blue-600">Contact</Link>
          <Link to="/get-started" className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
            Get Started
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
