// src/App.js
import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import GetStarted from './pages/GetStarted';
import Footer from './components/Footer';
import BackToTopButton from './components/BackToTopButton';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import Insights from './pages/Insights';
import Contact from './pages/Contact';
import Blogs from './pages/Blogs';
import Whitepapers from './pages/Whitepapers';
import Testimonials from './pages/Testimonials';
import CloudCostOptimization from './pages/insights/CloudCostOptimization';
import AICybersecurity from './pages/insights/AICybersecurity';
import MultiCloudManagement from './pages/insights/MultiCloudManagement';
import DigitalTransformation from './pages/insights/DigitalTransformation';
import DataPrivacyCompliance from './pages/insights/DataPrivacyCompliance';
import CloudSecurityBestPractices from './pages/insights/CloudSecurityBestPractices';
import ReduceCloudCosts from './pages/blogs/ReduceCloudCosts';
import AIInCloudSecurity from './pages/blogs/AIInCloudSecurity';
import CloudMigrationBestPractices from './pages/blogs/CloudMigrationBestPractices';
import MultiCloudStrategies from './pages/blogs/MultiCloudStrategies';
import FinOpsInCloudManagement from './pages/blogs/FinOpsInCloudManagement';
import FutureOfCloudAutomation from './pages/blogs/FutureOfCloudAutomation';

function App() {
  return (
    <Router>
      <Navbar />
      <div className="pt-20"> {/* Offset for fixed Navbar */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/insights" element={<Insights />} />
          <Route path="/insights/optimizing-cloud-costs" element={<CloudCostOptimization />} />
          <Route path="/insights/ai-in-cybersecurity" element={<AICybersecurity />} />
          <Route path="/insights/multi-cloud-management" element={<MultiCloudManagement />} />
          <Route path="/insights/digital-transformation-strategy" element={<DigitalTransformation />} />
          <Route path="/insights/data-privacy-compliance" element={<DataPrivacyCompliance />} />
          <Route path="/insights/cloud-security-best-practices" element={<CloudSecurityBestPractices />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/whitepapers" element={<Whitepapers />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/blogs/reduce-cloud-costs" element={<ReduceCloudCosts />} />
          <Route path="/blogs/ai-cloud-security" element={<AIInCloudSecurity />} />
          <Route path="/blogs/cloud-migration" element={<CloudMigrationBestPractices />} />
          <Route path="/blogs/multi-cloud-strategies" element={<MultiCloudStrategies />} />
          <Route path="/blogs/understanding-finops" element={<FinOpsInCloudManagement />} />
          <Route path="/blogs/future-cloud-automation" element={<FutureOfCloudAutomation />} />
        </Routes>
      </div>
      <Footer />
      <BackToTopButton />
    </Router>
  );
}

export default App;
