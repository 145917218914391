// src/pages/GetStarted.js
import React from 'react';
import Contact from './Contact';

const GetStarted = () => {
  return (
    <Contact
      title="Get Started with Invictus"
      subtitle="Ready to begin your cloud optimization journey? Let’s discuss how we can help."
      formTitle="Tell Us About Your Project"
      buttonText="Get Started"
    />
  );
};

export default GetStarted;
