// src/pages/Blogs.js
import React from 'react';

const Blogs = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section relative bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20 fade-in">
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">Our Blog</h1>
          <p className="text-xl mb-6 animate-fade-in delay-1s">Explore our latest articles, insights, and guides on cloud management, AI, and more.</p>
        </div>
      </div>

      {/* Blogs Grid Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Latest Articles</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            <BlogCard
              title="How to Reduce Cloud Costs"
              description="Discover effective strategies to cut down on cloud expenses without compromising performance."
              link="/blogs/reduce-cloud-costs"
            />
            <BlogCard
              title="AI in Cloud Security"
              description="Learn how AI is revolutionizing cloud security and helping businesses stay ahead of threats."
              link="/blogs/ai-cloud-security"
            />
            <BlogCard
              title="Multi-Cloud Strategies for Enterprises"
              description="A deep dive into the benefits and challenges of adopting a multi-cloud approach."
              link="/blogs/multi-cloud-strategies"
            />
            <BlogCard
              title="Cloud Migration Best Practices"
              description="Step-by-step guide on how to successfully migrate your workloads to the cloud."
              link="/blogs/cloud-migration"
            />
            <BlogCard
              title="Understanding FinOps in Cloud Management"
              description="Explore FinOps and how it can optimize your financial operations in the cloud."
              link="/blogs/understanding-finops"
            />
            <BlogCard
              title="The Future of Cloud Automation"
              description="Get insights into emerging trends and the future of automation in cloud management."
              link="/blogs/future-cloud-automation"
            />
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="text-center bg-blue-600 text-white py-16">
        <h2 className="text-3xl font-semibold mb-4">Stay Updated with Our Latest Posts</h2>
        <p className="text-lg mb-6">Subscribe to our newsletter to receive updates on the latest blog posts and industry insights.</p>
        <a href="/contact" className="bg-white text-blue-600 px-6 py-3 rounded font-semibold hover:bg-gray-200 transform hover:scale-105 transition duration-200">Subscribe Now</a>
      </section>
    </div>
  );
};

// BlogCard Component for each blog post
const BlogCard = ({ title, description, link }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 text-left">
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p className="text-gray-700 mb-4">{description}</p>
    <a href={link} className="text-blue-500 hover:underline">Read More</a>
  </div>
);

export default Blogs;
